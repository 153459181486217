<template>
  <div style="padding-bottom: 14px;">
    <div class="title_1" style="margin-top: 14px;">团体类竞赛</div>
    <template v-if="modelList.length > 0">
      <div style="padding: 0 14px;margin-top: 14px;position: relative;" :key="index"
           v-for="(item, index) in modelList">
        <div style="position: absolute;top: 0;right: 14px;z-index: 1;">
          <van-button @click="deleteModel(index)" type="danger" size="mini">删除</van-button>
        </div>
        <van-form style="margin-top: 14px" :colon="true">

          <van-field
              v-model="item.sortNo"
              label="序号"
              readonly
              required
          />

          <van-field
              v-model="item.name"
              label="队伍名称"
              placeholder="请填写队伍名称"
              clearable
              required
          />

          <van-field
              label="团队成员"
              required
              label-width="5em"
              style="position: relative"
          >
            <template #input>
              <div></div>
            </template>
            <template #button>
              <van-button @click="addMember(item)" style="font-size: 1.4em;padding: 0 5px;"
                          type="primary" size="mini">+
              </van-button>
            </template>
          </van-field>
          <template v-if="item.memberList && item.memberList.length">
            <div :key="index" v-for="(member, index) in item.memberList"
                 style="padding-left: 18px;background-color: #fff;">
              <van-field
                  v-model="member.memberName"
                  label="姓名"
                  placeholder="请填写姓名"
                  clearable
                  required
                  label-width="2.5em"
              />
              <van-field
                  label="性别"
                  required
                  label-width="2.5em"
              >
                <template #input>
                  <van-radio-group v-model="member.memberSex" direction="horizontal">
                    <van-radio name="1">男</van-radio>
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                      v-model="member.memberSfzh"
                      label="身份证号"
                      placeholder="请填写身份证号"
                      clearable
                      required
                      label-width="2.5em"
              />

            </div>
          </template>

          <van-field
              label="参与赛项"
              required
          >
            <template #input>
              <van-radio-group v-model="item.competitionType" direction="vertical">
                <van-radio name="1">团体接力飞行赛（4人）</van-radio>
                <van-radio name="2">空中格斗赛（2-3人）</van-radio>
                <van-radio name="3">空中足球赛（2-3人）</van-radio>
                <van-radio name="4">蜂群舞蹈编程赛（2人）</van-radio>
                <van-radio name="5">创意图形编程赛（2-3人）</van-radio>
                <van-radio name="6">“蜂鸟行动”赛（2-3人）</van-radio>
                <van-radio name="7">团体察打赛（2-3人）</van-radio>
                <van-radio name="13">动力飞行器创意赛（1-3人） </van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
              v-model="item.instructor"
              label="指导老师"
              placeholder="请填写指导老师"
              clearable
              required
          />
        </van-form>
      </div>
    </template>
    <div style="padding: 0px 14px;margin-top: 14px;">
      <van-button @click="addConfirm" type="info" block>新增</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamCompetitionForm",
  props: {
    id: {
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      modelList: [],
      sortNo: 0 //暂存序号
    }
  },
  methods: {
    addMember(item) {
      let member = {
        memberName: '',
        memberSex: '',
        memberSfzh:''
      }
      item.memberList.push(member)
    },
    addConfirm() {
      this.$dialog.confirm({
        title: "新增",
        message: "是否新增一项"
      }).then(() => {
        this.addOneEmptyFormObj()
      }).catch(() => {

      })
    },
    addOneEmptyFormObj() {
      this.sortNo = this.sortNo + 1
      let tmpSortNo = this.sortNo
      let objParam = {
        sortNo: tmpSortNo,
        name: undefined,
        competitionType: undefined,
        memberList: [],
        // memberName: undefined,
        // sex: undefined,
        instructor: undefined
      }
      this.modelList.push(objParam)
    },
    // 删除当前 form
    deleteModel(index) {
      this.$dialog.confirm({
        title: "删除",
        message: "是否删除这一项"
      }).then(() => {
        if (this.modelList.length > 0) {
          this.modelList.splice(index, 1);
          this.sortNo = 1;
          this.modelList.forEach(e => {
            e.sortNo = this.sortNo
            this.sortNo = this.sortNo + 1
          })
          this.sortNo = this.sortNo - 1
        }
      }).catch(() => {

      })
    }
  },
  mounted() {
    if (this.id === '' && this.modelList.length === 0) {
      this.addOneEmptyFormObj()
    }
  }
}
</script>

<style scoped>
</style>