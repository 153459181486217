<template>
  <div class="app_page_container">
    <van-sticky>
      <van-nav-bar
          title="信息填写"
          left-arrow
          @click-left="onClickLeft"
      />
      <!-- right-text="提交" -->
      <!-- @click-right="onClickRight" -->
    </van-sticky>
    <div class="title_1" v-if="matchConfig && matchConfig.title !== ''" style="margin-top: 14px;">
      {{ matchConfig.formTitle }}
    </div>
    <div class="title_1" style="margin-top: 7px;">{{ activeCategoryItem.itemText }}</div>

    <unit-form ref="unitFormRef"></unit-form>
    <personal-competition-form :id="id" ref="personalRef"></personal-competition-form>
    <team-competition-form :id="id" ref="teamRef"></team-competition-form>
<!--    <restaurant-order-form :id="id" ref="restaurantRef"></restaurant-order-form>-->
    <attachment ref="attachmentRef"></attachment>

    <div style="padding: 0 14px 28px 14px;">
      <van-button @click="onClickRight" type="primary" block>提交</van-button>
    </div>
  </div>
</template>

<script>
import UnitForm from "@/views/unit/UnitForm";
import PersonalCompetitionForm from "@/views/personal/PersonalCompetitionForm";
import TeamCompetitionForm from "@/views/term/TeamCompetitionForm";
import Attachment from "@/views/attachment/Attachment";
import {mapGetters, mapMutations} from 'vuex'
import {addSignUp} from '@/api/requestApi'
import {baseUrl, get, post} from '@/api/httpHelp'
import {Toast} from 'vant'

export default {
  name: "Form",
  components: {
    UnitForm,
    PersonalCompetitionForm,
    TeamCompetitionForm,
    Attachment
  },
  data() {
    return {
      id: '',
      submitIng: false,
      url: {
        add: '/signUp',
        detailSignUp: '/detailSignUp',
        updateSignUp: '/updateSignUp'
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    console.log(this.id)
    if (this.id && this.id !== '') {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters('match', ['matchConfig', 'activeCategoryItem', 'categoryItemVoList'])
  },
  methods: {
    ...mapMutations('match', ['SET_ACTIVE_CATEGORY_ITEM']),
    loadData: function () {
      const loading = Toast.loading({
        duration: 0,
        message: '加载中',
        forbidClick: true
      })
      get(this.url.detailSignUp, {
        unitId: this.id
      }).then(res => {
        if (res != null) {
          let data = res.result
          this.$refs.unitFormRef.model = data.unit
          if (!this.activeCategoryItem || Object.keys(this.activeCategoryItem).length <= 0) {
            if (this.categoryItemVoList && this.categoryItemVoList.length > 0) {
              this.categoryItemVoList.forEach(e => {
                if (e.itemValue == data.unit.category) {
                  this.SET_ACTIVE_CATEGORY_ITEM(e)
                }
              })
            }
          }

          if(data.personalCompetitionList && data.personalCompetitionList.length > 0){
            console.log("form personal list", data.personalCompetitionList)
            data.personalCompetitionList.forEach(e => {
              e.sex = e.sex + ''
              e.competitionType = e.competitionType + ''
            })
            this.$refs.personalRef.modelList = data.personalCompetitionList
          }

          if (data.teamCompetitionList && data.teamCompetitionList.length > 0) {
            data.teamCompetitionList.forEach(e => {
              e.competitionType = e.competitionType + ''
              if (e.memberList && e.memberList.length > 0) {
                e.memberList.forEach(item => {
                  item.memberSex = item.memberSex + ''
                })
              }
            })
            this.$refs.teamRef.modelList = data.teamCompetitionList
            this.$refs.teamRef.sortNo = data.teamCompetitionList.length
          }

          if (data.restaurantOrderList && data.restaurantOrderList.length > 0) {
            const orderTimeList = this.matchConfig.orderTimeList
            data.restaurantOrderList.forEach(e => {
              orderTimeList.forEach(item => {
                if(e.orderTime === item.itemValue){
                  e.orderTimeStr = item.itemText
                }
              })
            })
            this.$refs.restaurantRef.modelList = data.restaurantOrderList
            this.$refs.restaurantRef.index = data.restaurantOrderList.length
          }
          if (data.attachmentUrlList && data.attachmentUrlList.length > 0) {
            this.$refs.attachmentRef.modelList = data.attachmentUrlList.map(e => ({
              url: baseUrl + e
            }))
          }
        }
        loading.clear()
      })
    },
    checkStrNotEmpty(str) {
      return !(typeof str == "undefined" || str == null || str === '')
    },
    checkNumNotEmpty(num) {
      return !(typeof num == "undefined" || num == null || num < 0)
    },
    checkListNotEmpty(lst) {
      return !(typeof lst == "undefined" || lst == null || lst.length === 0)
    },
    verifyUnit(unit) {
      if (typeof unit == "undefined" || unit == null) {
        return false
      }
      return this.checkStrNotEmpty(unit.name) &&
          this.checkStrNotEmpty(unit.city) &&
          this.checkStrNotEmpty(unit.address) &&
          this.checkStrNotEmpty(unit.email) &&
          this.checkStrNotEmpty(unit.leader) &&
          this.checkStrNotEmpty(unit.leaderPhone)
    },
    verifyPersonalCompetitionList(personalCompetitionList) {
      if (typeof personalCompetitionList == "undefined" || personalCompetitionList == null || personalCompetitionList.length === 0) {
        return true
      }
      let that = this
      let flag = false
      for (let i = 0; i < personalCompetitionList.length; i++) {
        let item = personalCompetitionList[i]
        flag = !!(that.checkStrNotEmpty(item.name) &&
            that.checkStrNotEmpty(item.instructor) &&
            that.checkNumNotEmpty(item.sex) &&
                that.checkNumNotEmpty(item.sfzh) &&
            that.checkNumNotEmpty(item.competitionType));
      }
      return flag
    },
    checkMemberListNotEmpty(memberList) {
      if (!memberList || memberList.length === 0) {
        return false
      }
      let flag = false
      for (let i = 0; i < memberList.length; i++) {
        let member = memberList[i]
        if (!member || member.memberName === '' || member.memberSex === '' || member.memberSfzh === '') {
          flag = false;
          break;
        } else {
          flag = true
        }
      }
      return flag
    },
    verifyTeamCompetitionList(teamCompetitionList) {
      if (!this.checkListNotEmpty(teamCompetitionList)) {
        return true
      }
      let that = this
      let flag = false
      for (let i = 0; i < teamCompetitionList.length; i++) {
        let item = teamCompetitionList[i]
        flag = !!(that.checkStrNotEmpty(item.name) &&
            that.checkStrNotEmpty(item.instructor) &&
            that.checkMemberListNotEmpty(item.memberList) &&
            that.checkNumNotEmpty(item.competitionType));
      }
      return flag
    },
    verifyRestaurantOrderList(restaurantOrderList) {
      if (!this.checkListNotEmpty(restaurantOrderList)) {
        return true
      }
      let that = this
      let flag = false
      for (let i = 0; i < restaurantOrderList.length; i++) {
        let item = restaurantOrderList[i]
        flag = !!(that.checkStrNotEmpty(item.orderTime) &&
            that.checkNumNotEmpty(item.launchNum) &&
            that.checkNumNotEmpty(item.dinnerNum));
      }
      return flag
    },
    verifyAttachmentList(attachmentList) {
      return !(typeof attachmentList == "undefined" || attachmentList == null || attachmentList.length === 0);

    },
    verifyAllForm(unit, personalCompetitionList, teamCompetitionList, restaurantOrderList, attachmentList) {
      if (!this.verifyUnit(unit)) {
        Toast.fail("请完善单位信息")
        return false
      }
      if (!this.verifyPersonalCompetitionList(personalCompetitionList)) {
        Toast.fail("请完善个人信息")
        return false
      }
      if (!this.verifyTeamCompetitionList(teamCompetitionList)) {
        Toast.fail("请完善团队信息")
        return false
      }
      // if (!this.verifyRestaurantOrderList(restaurantOrderList)) {
      //   Toast.fail("请完善餐饮预订信息")
      //   return false
      // }
      if (!this.verifyAttachmentList(attachmentList)) {
        Toast.fail("请上传附件图片")
        return false
      }
      return true
    },
    onClickRight() {
      if (this.submitIng) {
        return
      }
      const unit = this.$refs.unitFormRef.model
      const personalCompetitionList = this.$refs.personalRef.modelList

      const teamCompetitionList = this.$refs.teamRef.modelList
      // const restaurantOrderList = this.$refs.restaurantRef.modelList
      const restaurantOrderList = [];
      const attachmentList = this.$refs.attachmentRef.genAttachmentList()
      if (!this.verifyAllForm(unit, personalCompetitionList, teamCompetitionList, restaurantOrderList, attachmentList)) {
        return;
      }
      // 设置小组类型
      unit.category = this.activeCategoryItem.itemValue
      console.log("category", this.activeCategoryItem.itemValue)
      unit.openId = window.localStorage.getItem('openId')
      restaurantOrderList.forEach(e => {
        this.$delete(e, 'orderTimeStr')
      })
      let param = {
        unit: unit,
        personalCompetitionList: personalCompetitionList,
        teamCompetitionList: teamCompetitionList,
        restaurantOrderList: restaurantOrderList,
        attachmentList: attachmentList
      }
      this.submitIng = true
      const loadingToast = Toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      console.log("param", param)
      let that = this
      console.log("this.id 2", this.id)
      if(this.id && this.id !== ''){
        post(this.url.updateSignUp, param).then(res => {
          loadingToast.clear();
          if(res != null){
            Toast.success({
              message: '更新成功',
              forbidClick: true,
              duration: 1500,
              onClose() {
                that.$router.push({
                  path: '/wx/submitOk'
                })
              }
            })
          }
        }).catch(() => {
        }).finally(() => {
        })
        return
      }
      addSignUp(this.url.add, param).then(res => {
        console.log("结果", res)
        loadingToast.clear();
        if (res != null) {
          Toast.success({
            message: '提交成功',
            forbidClick: true,
            duration: 1500,
            onClose() {
              that.$router.push({
                path: '/wx/submitOk'
              })
            }
          })
        }
      }).catch(()=>{
      }).finally(()=>{
      })
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>