<template>
  <div style="padding: 0 14px 14px 14px;">
    <div style="display: flex;">
      <van-uploader accept="image/*" v-model="modelList" @delete="deleteOne" :multiple="multiple" :after-read="afterRead"/>
    </div>
    <div style="margin: 14px 0;">
      <span style="font-weight: bold;line-height: 22px;color: red;">*将盖章后的文件拍照上传</span>
    </div>
    <div style="padding-top:14px;background-color: #FFF;max-width: 300px;">
      <div style="margin:0 22px;text-align: center;">
        <span style="color: #333;font-weight: bold;">纸质报名表到群里下载</span>
      </div>
      <van-image :src="dingdingImage"></van-image>
    </div>
  </div>
</template>

<script>
import {post, baseUrl} from "@/api/httpHelp";

export default {
  name: "Attachment",
  props: {
    id: {
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      multiple: 'true',
      modelList: [],
      url: {
        upload: '/upload/image'
      },
      dingdingImage: require('@/assets/images/dingding2023.jpg')
    }
  },
  methods: {
    genAttachmentList() {
      let fileUrlList = []
      this.modelList.forEach(e => {
        if (e.uploadUrl) {
          fileUrlList.push(e.uploadUrl)
        } else {
          if (e.url) {
            fileUrlList.push(e.url.replaceAll(baseUrl, ''))
          }
        }
      })
      console.log(fileUrlList)
      return fileUrlList
    },
    deleteOne() {
      console.log(this.modelList)
    },
    afterRead(fileList) {
      let tmpList = []
      if(fileList instanceof Array){
        fileList.forEach(e => {
          tmpList.push(e)
        })
      } else {
        tmpList.push(fileList)
      }
      tmpList.forEach(item => {
        if (!item.file || item.file.size <= 0) {
          this.$toast.fail("文件异常")
          return
        }
        item.status = "uploading"
        item.message = "上传中..."
        let data = new FormData();
        data.append("file", item.file);
        post(this.url.upload, data).then(res => {
          if (res != null) {
            item.status = ""
            item.message = ''
            item.uploadUrl = res.message
          }
        }).catch(() => {
        }).finally(() => {
        })
      })
    },
  }
}
</script>

<style scoped>

</style>