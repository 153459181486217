<template>
  <div style="padding-bottom: 14px;">
    <div class="title_1" style="margin-top: 14px;">个人类竞赛</div>
    <div style="padding: 0 14px;margin-top: 14px;position: relative;" :key="index" v-for="(item, index) in modelList">
      <div style="position: absolute;top: 0;right: 14px;z-index: 1;">
        <van-button @click="deleteModel(index)" type="danger" size="mini">删除</van-button>
      </div>
      <van-form style="margin-top: 14px" :colon="true">
        <van-field
            v-model="item.sortNo"
            label="序号"
            placeholder="请填写序号"
            readonly
            required
        />
        <van-field
            v-model="item.name"
            label="姓名"
            placeholder="请填写姓名"
            clearable
            required
            :rules="[{
              required: true,
              message: '请填写姓名'
            }]"
        />
        <van-field
                v-model="item.sfzh"
                label="身份证号"
                placeholder="请填写身份证号"
                clearable
                required
                :rules="[{
              required: true,
              message: '请填写身份证号'
            }]"
        />
        <van-field
            label="性别"
            :rules="[{
              required: true,
              message: '请选择性别'
            }]"
            required
        >
          <template #input>
            <van-radio-group v-model="item.sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
            label="参与赛项"
            :rules="[{
              required: true,
              message: '请选择参与赛项'
            }]"
            required
        >
          <template #input>
            <van-radio-group v-model="item.competitionType" direction="vertical">
              <van-radio name="1">个人飞行赛</van-radio>
              <van-radio name="2">物流搬运赛</van-radio>
              <van-radio name="3">个人空中射击赛</van-radio>
              <van-radio name="4">编程挑战赛</van-radio>
              <van-radio name="5">越障打击赛</van-radio>
              <van-radio name="6">编程空中搜寻赛</van-radio>
              <van-radio name="7">“雷霆飞途”赛</van-radio>
              <van-radio name="8">空中侦察赛(第一视角)</van-radio>
              <van-radio name="9">个人侦察赛</van-radio>
              <van-radio name="10">模拟飞行紧急迫降挑战赛</van-radio>
              <van-radio name="11">模拟飞行大飞机转场挑战赛</van-radio>
              <van-radio name="12">模拟飞行个人竞速赛</van-radio>

              <van-radio name="14">无人机应用场景创意编程赛</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
            v-model="item.instructor"
            label="指导老师"
            placeholder="请填写指导老师"
            clearable
            required
            :rules="[{
              required: true,
              message: '请填写指导老师'
            }]"
        />
      </van-form>
    </div>
    <div style="padding: 0 14px;margin-top: 14px;">
      <van-button @click="addConfirm" type="info" block>新增</van-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "PersonalCompetitionForm",
  props: {
    id: {
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      modelList: [],
      sortNo: 0 //暂存序号
    }
  },
  computed: {
    ...mapGetters('match', ['matchConfig'])
  },
  methods: {
    addConfirm() {
      this.$dialog.confirm({
        title: "新增",
        message: "是否新增一项"
      }).then(() => {
        this.addOneEmptyFormObj()
      }).catch(() => {
      })
    },
    addOneEmptyFormObj() {
      this.sortNo = this.sortNo + 1
      let tmpSortNo = this.sortNo
      let objParam = {
        sortNo: tmpSortNo,
        name: undefined,
        sfzh: undefined,
        sex: undefined,
        competitionType: undefined,
        instructor: undefined
      }
      this.modelList.push(objParam)
    },
    // 删除当前 form
    deleteModel(index) {
      this.$dialog.confirm({
        title: "删除",
        message: "是否删除这一项"
      }).then(() => {
        if (this.modelList.length > 0) {
          this.modelList.splice(index, 1);
          this.sortNo = 1;
          this.modelList.forEach(e => {
            e.sortNo = this.sortNo
            this.sortNo = this.sortNo + 1
          })
          this.sortNo = this.sortNo - 1
        }
      }).catch(() => {

      })
    }
  },
  mounted() {
    if (this.id === '' && this.modelList.length === 0) {
      console.log("in add personal")
      this.addOneEmptyFormObj()
    }
  }
}
</script>

<style scoped>

</style>