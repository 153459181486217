var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-bottom":"14px"}},[_c('div',{staticClass:"title_1",staticStyle:{"margin-top":"14px"}},[_vm._v("个人类竞赛")]),_vm._l((_vm.modelList),function(item,index){return _c('div',{key:index,staticStyle:{"padding":"0 14px","margin-top":"14px","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"0","right":"14px","z-index":"1"}},[_c('van-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.deleteModel(index)}}},[_vm._v("删除")])],1),_c('van-form',{staticStyle:{"margin-top":"14px"},attrs:{"colon":true}},[_c('van-field',{attrs:{"label":"序号","placeholder":"请填写序号","readonly":"","required":""},model:{value:(item.sortNo),callback:function ($$v) {_vm.$set(item, "sortNo", $$v)},expression:"item.sortNo"}}),_c('van-field',{attrs:{"label":"姓名","placeholder":"请填写姓名","clearable":"","required":"","rules":[{
            required: true,
            message: '请填写姓名'
          }]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('van-field',{attrs:{"label":"身份证号","placeholder":"请填写身份证号","clearable":"","required":"","rules":[{
            required: true,
            message: '请填写身份证号'
          }]},model:{value:(item.sfzh),callback:function ($$v) {_vm.$set(item, "sfzh", $$v)},expression:"item.sfzh"}}),_c('van-field',{attrs:{"label":"性别","rules":[{
            required: true,
            message: '请选择性别'
          }],"required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(item.sex),callback:function ($$v) {_vm.$set(item, "sex", $$v)},expression:"item.sex"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("男")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("女")])],1)]},proxy:true}],null,true)}),_c('van-field',{attrs:{"label":"参与赛项","rules":[{
            required: true,
            message: '请选择参与赛项'
          }],"required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"vertical"},model:{value:(item.competitionType),callback:function ($$v) {_vm.$set(item, "competitionType", $$v)},expression:"item.competitionType"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("个人飞行赛")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("物流搬运赛")]),_c('van-radio',{attrs:{"name":"3"}},[_vm._v("个人空中射击赛")]),_c('van-radio',{attrs:{"name":"4"}},[_vm._v("编程挑战赛")]),_c('van-radio',{attrs:{"name":"5"}},[_vm._v("越障打击赛")]),_c('van-radio',{attrs:{"name":"6"}},[_vm._v("编程空中搜寻赛")]),_c('van-radio',{attrs:{"name":"7"}},[_vm._v("“雷霆飞途”赛")]),_c('van-radio',{attrs:{"name":"8"}},[_vm._v("空中侦察赛(第一视角)")]),_c('van-radio',{attrs:{"name":"9"}},[_vm._v("个人侦察赛")]),_c('van-radio',{attrs:{"name":"10"}},[_vm._v("模拟飞行紧急迫降挑战赛")]),_c('van-radio',{attrs:{"name":"11"}},[_vm._v("模拟飞行大飞机转场挑战赛")]),_c('van-radio',{attrs:{"name":"12"}},[_vm._v("模拟飞行个人竞速赛")]),_c('van-radio',{attrs:{"name":"14"}},[_vm._v("无人机应用场景创意编程赛")])],1)]},proxy:true}],null,true)}),_c('van-field',{attrs:{"label":"指导老师","placeholder":"请填写指导老师","clearable":"","required":"","rules":[{
            required: true,
            message: '请填写指导老师'
          }]},model:{value:(item.instructor),callback:function ($$v) {_vm.$set(item, "instructor", $$v)},expression:"item.instructor"}})],1)],1)}),_c('div',{staticStyle:{"padding":"0 14px","margin-top":"14px"}},[_c('van-button',{attrs:{"type":"info","block":""},on:{"click":_vm.addConfirm}},[_vm._v("新增")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }