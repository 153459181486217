<template>
  <div style="padding: 14px;">
    <van-form :colon="true">
      <van-field
          v-model="model.name"
          label="单位名称"
          placeholder="单位名称"
          clearable
          required
      />
      <van-field
          v-model="model.city"
          label="地级市"
          placeholder="如：杭州、湖州、嘉兴"
          clearable
          required
      />
      <van-field
          v-model="model.address"
          type="textarea"
          rows="3"
          :autosize="true"
          placeholder="单位地址"
          label="单位地址"
          clearable
          show-word-limit
          maxlength="1000"
          required
      />

      <van-field
          v-model="model.email"
          label="联系邮箱"
          placeholder="联系邮箱"
          clearable
          required
      />

      <van-field
          v-model="model.leader"
          label="领队姓名"
          placeholder="领队姓名"
          clearable
          required
      />

      <van-field
          v-model="model.leaderPhone"
          label="联系电话"
          placeholder="联系电话"
          clearable
          required
      />

      <van-field
          v-model="model.secondLeader"
          label="副领队"
          placeholder="副领队"
          clearable
      />

      <van-field
          v-model="model.secondLeaderPhone"
          label="联系电话"
          placeholder="联系电话"
          clearable
      />
    </van-form>
  </div>
</template>

<script>
export default {
  name: "UnitForm",
  props: {
    id: {
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      model: {
        name: '',
        city: '',
        address: '',
        email: '',
        leader: '',
        leaderPhone: '',
        secondLeader: '',
        secondLeaderPhone: ''
      }
    }
  }
}
</script>

<style scoped>

</style>